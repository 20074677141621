// import logo from './logo.svg';
import './App.css';
import React, { Suspense, useEffect, useState } from 'react';
import { useLocation, Routes, Route } from 'react-router-dom';

import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "./authConfig";

const EditContact = React.lazy(() => import('./components/grid/grid-wrapper.jsx'));
const Header = React.lazy(() => import('./components/header.jsx'));
const Splash = React.lazy(() => import("./components/splash"));
const ProductList = React.lazy(() => import("./components/product-list"));
const Statement = React.lazy(() => import("./components/mission-statement"));
const Services = React.lazy(() => import("./components/services"));
const More = React.lazy(() => import("./components/view-more"));
const Contact = React.lazy(() => import("./components/contact-us"));
const Footer = React.lazy(() => import("./components/footer"));

const ThankYou = React.lazy(() => import("./components/thank-you.jsx"))

export const metadata = {
  title: "FTL Technology Lab",
  description: "Generated by FTL Technology Lab 2024",
}

function App(props) {
  const location = useLocation();
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [forceLogin, setForceLogin] = useState(false)

  const [isInit, setIsInit] = useState(false)
  const [token, setToken] = useState({})

  function showAdmin() {
    if (isAuthenticated && token && token.account && instance.controller.initialized) {
      if (token.account.idTokenClaims.emails[0]
        === "gbooneoh@gmail.com") {
        return true
      }
    } else {
      instance.initialize()
    }
    return false
  }

  const elements = [
    <Splash />,
    <Statement />,
    <Services />,
    <ProductList />,
    <More />,
    <Contact isDevMode={props.isDevMode} />,
    <Footer />
  ]

  useEffect(() => {
    document.title = metadata.title
    document.description = metadata.description
  }, [])

  useEffect(() => {
    if (location.pathname === "/admin" && !isAuthenticated) {
      setForceLogin(true)
    }
  }, [location.pathname, isAuthenticated])

  // async function handleRedirectPromiseAsync(instance) {
  //   await instance.handleRedirectPromise().then(response => {
  //     if (response) {

  //       // localStorage.setItem("accessToken", response.accessToken);
  //       // localStorage.setItem("idToken", response.idToken);
  //       setToken(response)
  //     }
  //   }).catch(error => {
  //     console.error("Error handling redirect:", error);
  //   });
  // }

  useEffect(() => {
    if (instance && instance.controller.initialized) {
      const handleRedirectPromiseAsync = async () => {
        try {
          const response = await instance.handleRedirectPromise()
          if (response) {
            
          // localStorage.setItem("accessToken", response.accessToken);
          // localStorage.setItem("idToken", response.idToken);
            setToken(response);
          }
        } catch (error) {
          console.error("Error handling redirect:", error)
        }
      }

      handleRedirectPromiseAsync()
    }
  }, [instance])

  useEffect(() => {
    console.log(instance)
    if (!isInit) {
      instance.initialize()
      setIsInit(true)
    } else if (instance.controller.initialized && location.pathname === "/admin") {
      const handleLogin = (instance, loginType) => {
        if (loginType === "popup") {
          instance.loginPopup(loginRequest).catch(e => {
            console.log(e);
          });
        } else if (loginType === "redirect") {
          instance.loginRedirect(loginRequest)
            .catch(e => {
              console.log(e);
            });
        }
      }
      handleLogin(instance, "redirect")
    }
  }, [instance, isInit, forceLogin, location.pathname])

  return (
    <div>
      <Suspense fallback={<div>Loading page...</div>}></Suspense>
      <Routes>
        <Route path="/" element={<Header />}>
          <Route index element={elements} />
          <Route path="thanks" element={<ThankYou />} />
          {showAdmin() ? <Route path="admin" element={<EditContact isDevMode={props.isDevMode} token={token} />} /> : <></>}
        </Route>
      </Routes>
    </div>
  );
}

export default App;
